html,
body,
#root {
  width: 100%;
  height: 100%;
}
.answer-card {
  border-radius: 50px;
  background: #1e3a8a;
  box-shadow: 20px 20px 50px #1a3175, -20px -20px 50px #23439f;
}
